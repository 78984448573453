const galleries = document.querySelectorAll(".gallery");

galleries?.forEach((gallery) => {
  const mainImage = gallery.querySelector(".main-image");
  const mainImageButton = gallery.querySelector(".main-image-button");
  const previewImageButtons = gallery.querySelectorAll(".preview-image");

  previewImageButtons?.forEach((previewImageButton) => {
    const previewImageSrc = previewImageButton.firstElementChild?.src;
    const previewImageAlt = previewImageButton.firstElementChild?.alt;
    const previewImageIndex = previewImageButton.dataset?.index;
    previewImageButton.addEventListener("click", () => {
      mainImage.src = previewImageSrc;
      mainImage.alt = previewImageAlt;
      mainImageButton.dataset.index = previewImageIndex;
    });
  });
});
