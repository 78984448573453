const navigationMenuButton = document.querySelector(".navigation-menu-button");
const navigationMenuCloseButton = document.querySelector(
  ".navigation-menu-close-button"
);
const navigationMenuCloseButtonSmall = document.querySelector(
  ".navigation-menu-close-button-small"
);
const navigationMenu = document.querySelector(".navigation-menu");

navigationMenuButton?.addEventListener("click", () => {
  navigationMenu?.classList.remove("hide");
  navigationMenuButton?.setAttribute("aria-expanded", "true");
});

navigationMenuCloseButton?.addEventListener("click", () => {
  navigationMenu?.classList.add("hide");
  navigationMenuButton?.setAttribute("aria-expanded", "false");
});

navigationMenuCloseButtonSmall?.addEventListener("click", () => {
  navigationMenu?.classList.add("hide");
  navigationMenuButton?.setAttribute("aria-expanded", "false");
});

const accordionItems = navigationMenu?.querySelectorAll(
  "[data-accordion-target]"
);

accordionItems?.forEach((accordionItem) => {
  const targetId = accordionItem.getAttribute("data-accordion-target");
  const target = document.querySelector(`#${targetId}`);
  accordionItem.addEventListener("click", (event) => {
    event.preventDefault();
    target?.classList.toggle("hide");
    const isExpanded = accordionItem.getAttribute("aria-expanded");
    accordionItem.setAttribute(
      "aria-expanded",
      isExpanded === "false" ? "true" : "false"
    );
  });
});
