const filterMenuButtons = document.querySelectorAll(".filter-menu-button");

const filterMenuCloseButtons = document.querySelectorAll(
  ".filter-menu-close-button"
);
const filterMenuCloseButtonsSmall = document.querySelectorAll(
  ".filter-menu-close-button-small"
);
const filterMenu = document.querySelector(".filter-menu");

filterMenuButtons?.forEach((filterMenuButton) => {
  const target = filterMenuButton.dataset?.target;
  const targetElement = document.querySelector(`#${target}`);
  const options = document.querySelectorAll(".filter-menu-option");
  filterMenuButton?.addEventListener("click", () => {
    if (targetElement) {
      options?.forEach((option) => option.classList.add("hide"));
      targetElement.classList.remove("hide");
    }
    filterMenu?.classList.remove("hide");
    setTimeout(() => filterMenuCloseButtons?.[0]?.focus(), 100);
  });
});

filterMenuCloseButtons?.forEach((filterMenuCloseButton) => {
  filterMenuCloseButton?.addEventListener("click", () => {
    filterMenu?.classList.add("hide");
    setTimeout(() => filterMenuButtons?.[0]?.focus(), 100);
  });
});

filterMenuCloseButtonsSmall?.forEach((filterMenuCloseButtonSmall) => {
  filterMenuCloseButtonSmall?.addEventListener("click", () => {
    filterMenu?.classList.add("hide");
    setTimeout(() => filterMenuButtons?.[0]?.focus(), 100);
  });
});

const filterMenuAccordionItems = filterMenu?.querySelectorAll(
  "[data-accordion-target]"
);

filterMenuAccordionItems?.forEach((accordionItem) => {
  const targetId = accordionItem.getAttribute("data-accordion-target");
  const target = document.querySelector(`#${targetId}`);
  accordionItem.addEventListener("click", (event) => {
    event.preventDefault();
    target.classList.toggle("hide");
    const isExpanded = accordionItem.getAttribute("aria-expanded");
    accordionItem.setAttribute(
      "aria-expanded",
      isExpanded === "false" ? "true" : "false"
    );
  });
});
