const section = document.querySelector(".secondary-navigation-section");
const wrapper = document.querySelector(
  "[data-secondary-navigation-outer-wrapper]"
);
const buttonRight = document.querySelector(
  "[data-secondary-navigation-scroll-button-right]"
);
const buttonLeft = document.querySelector(
  "[data-secondary-navigation-scroll-button-left]"
);

buttonRight?.addEventListener("click", () => {
  wrapper?.scrollBy({ left: 50 });
});

buttonLeft?.addEventListener("click", () => {
  wrapper?.scrollBy({ left: -50 });
});

wrapper?.addEventListener("scroll", () => {
  const scrollWidth = wrapper.scrollWidth;
  const scrollLeft = wrapper.scrollLeft;
  const clientWidth = wrapper.clientWidth;
  const scrollProgress = clientWidth + scrollLeft;
  const canScrollRight = scrollWidth !== scrollProgress;
  const canScrollLeft = scrollLeft !== 0;
  if (canScrollRight) {
    buttonRight.classList.remove("hide");
    section?.classList.remove("hide-after");
  } else {
    buttonRight.classList.add("hide");
    section?.classList.add("hide-after");
  }
  if (canScrollLeft) {
    buttonLeft.classList.remove("hide");
    section?.classList.remove("hide-before");
  } else {
    buttonLeft.classList.add("hide");
    section?.classList.add("hide-before");
  }
});
