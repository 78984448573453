import EmblaCarousel from "embla-carousel";

const rootNodes = document.querySelectorAll(".embla");

rootNodes?.forEach((rootNode) => {
  const viewportNode = rootNode?.querySelector(".embla-viewport");
  const prevButtonNode = rootNode?.querySelector(".embla-prev");
  const nextButtonNode = rootNode?.querySelector(".embla-next");

  const options = { loop: false, align: 0 };

  if (viewportNode) {
    const embla = EmblaCarousel(viewportNode, options);

    prevButtonNode?.addEventListener("click", embla.scrollPrev, false);
    nextButtonNode?.addEventListener(
      "click",
      () => {
        if (
          embla.selectedScrollSnap() + embla.slidesInView().length <
          embla.scrollSnapList().length
        ) {
          embla.scrollNext();
        }
      },
      false
    );
  }
});
