const resultsTables = document.querySelectorAll(".results-table-section");

resultsTables?.forEach((resultsTable) => {
  const resultsTableAccordionItems = resultsTable?.querySelectorAll(
    "[data-accordion-target]"
  );

  resultsTableAccordionItems?.forEach((accordionItem) => {
    const targetId = accordionItem.getAttribute("data-accordion-target");
    const target = document.querySelector(`#${targetId}`);
    const containingRow = target?.closest(".item-row");
    accordionItem.addEventListener("click", (event) => {
      event.preventDefault();
      target?.classList.toggle("hide");
      containingRow?.classList.toggle("expanded");
      const isExpanded = accordionItem.getAttribute("aria-expanded");
      accordionItem.setAttribute(
        "aria-expanded",
        isExpanded === "false" ? "true" : "false"
      );
    });
  });
});
