const modalButtons = document.querySelectorAll("[data-modal-target]");
const modals = document.querySelectorAll(".modal, .export-modal");

modalButtons?.forEach((modalButton) => {
  const targetId = modalButton.getAttribute("data-modal-target");
  const target = document.querySelector(`#${targetId}`);
  modalButton.addEventListener("click", (event) => {
    event.preventDefault();
    target?.classList.remove("hide");
    document.body.style.position = "fixed";
    document.body.style.top = `-${window.scrollY}px`;
    document.body.style.paddingRight = `17px`;
  });
});

modals?.forEach((modal) => {
  const closeButton = modal.querySelector(".modal-close-button");
  closeButton?.addEventListener("click", () => {
    modal.classList.add("hide");
    const scrollY = document.body.style.top;
    document.body.style.position = "";
    document.body.style.top = "";
    document.body.style.paddingRight = "";
    window.scrollTo(0, parseInt(scrollY || "0") * -1);
  });
});
