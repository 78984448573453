const accordions = document.querySelectorAll("[data-accordion]");

accordions?.forEach((accordion) => {
  const headings = accordion.querySelectorAll("[data-accordion-target]");

  headings?.forEach((heading) => {
    const targetId = heading.getAttribute("data-accordion-target");
    const target = document.querySelector(`#${targetId}`);
    heading.addEventListener("click", (event) => {
      event.preventDefault();
      target?.classList.toggle("hide");
      const isExpanded = heading.getAttribute("aria-expanded");
      heading.setAttribute(
        "aria-expanded",
        isExpanded === "false" ? "true" : "false"
      );
    });
  });
});

const expandAllButtons = document.querySelectorAll("[data-expand-all]");

expandAllButtons?.forEach((button) => {
  console.log(button);
  button.addEventListener("click", () => {
    const target = button.dataset?.target;
    const targetElement = document.querySelector(`#${target}`);

    const accordions = targetElement.querySelectorAll("[data-accordion]");

    accordions?.forEach((accordion) => {
      const headings = accordion.querySelectorAll("[data-accordion-target]");

      headings?.forEach((heading) => {
        const targetId = heading.getAttribute("data-accordion-target");
        const target = document.querySelector(`#${targetId}`);
        target?.classList.remove("hide");
        heading.setAttribute("aria-expanded", "true");
      });
    });
  });
});

const collapseAllButtons = document.querySelectorAll("[data-collapse-all]");

collapseAllButtons?.forEach((button) => {
  console.log(button);
  button.addEventListener("click", () => {
    const target = button.dataset?.target;
    const targetElement = document.querySelector(`#${target}`);

    const accordions = targetElement.querySelectorAll("[data-accordion]");

    accordions?.forEach((accordion) => {
      const headings = accordion.querySelectorAll("[data-accordion-target]");

      headings?.forEach((heading) => {
        const targetId = heading.getAttribute("data-accordion-target");
        const target = document.querySelector(`#${targetId}`);
        target?.classList.add("hide");
        heading.setAttribute("aria-expanded", "false");
      });
    });
  });
});
