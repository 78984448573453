const radioInputs = document.querySelectorAll("[data-radio-input]");

radioInputs?.forEach((radioInput) => {
  const inputButton = radioInput.querySelector("[data-radio-input-button]");
  const inputList = radioInput.querySelector("[data-radio-input-list]");

  const inputs = inputList?.querySelectorAll("input");
  inputs?.forEach((input) => {
    input?.addEventListener("focusout", function (event) {
      var isTabInside = inputList?.contains(event.relatedTarget);

      if (!isTabInside) {
        setTimeout(() => inputList?.classList.add("hide"), 300);
      }
    });
    input?.addEventListener("change", () => {
      inputList.previousElementSibling.innerHTML =
        input?.nextElementSibling?.innerHTML;
    });
  });

  const anchors = inputList?.querySelectorAll("a");
  anchors?.forEach((anchor) => {
    anchor?.addEventListener("focusout", function (event) {
      var isTabInside = inputList?.contains(event.relatedTarget);

      if (!isTabInside) {
        setTimeout(() => inputList?.classList.add("hide"), 300);
      }
    });
  });

  document.addEventListener("click", function (event) {
    var isClickInside =
      inputList?.contains(event.target) || inputButton?.contains(event.target);

    if (!isClickInside) {
      inputList?.classList.add("hide");
    }
  });

  inputButton?.addEventListener("click", () => {
    inputList?.classList.toggle("hide");
    inputList?.querySelector("input")?.focus();
  });
});
