import EmblaCarousel from "embla-carousel";

const rootNodes = document.querySelectorAll(".lightbox-embla");

rootNodes?.forEach((rootNode) => {
  const viewportNode = rootNode?.querySelector(".lightbox-embla-viewport");
  const prevButtonNode = rootNode?.querySelector(".lightbox-embla-prev");
  const nextButtonNode = rootNode?.querySelector(".lightbox-embla-next");

  const options = { loop: false, align: 0 };

  if (viewportNode) {
    const embla = EmblaCarousel(viewportNode, options);

    prevButtonNode?.addEventListener("click", embla.scrollPrev, false);
    nextButtonNode?.addEventListener("click", embla.scrollNext, false);

    const lightboxButtons = document.querySelectorAll("[data-lightbox-target]");
    const lightboxes = document.querySelectorAll(".lightbox-section");

    lightboxButtons?.forEach((lightboxButton) => {
      const targetId = lightboxButton.getAttribute("data-lightbox-target");
      const target = document.querySelector(`#${targetId}`);
      const targetCloseButton = target.querySelector(".lightbox-close-button");
      console.log(targetCloseButton);
      lightboxButton.addEventListener("click", (event) => {
        event.preventDefault();
        target?.classList.remove("hide");
        targetCloseButton?.focus();
        const index = lightboxButton.dataset.index;
        console.log(index);
        embla.scrollTo(index);
        document.body.style.position = "fixed";
        document.body.style.top = `-${window.scrollY}px`;
      });
    });

    lightboxes?.forEach((lightbox) => {
      const closeButton = lightbox.querySelector(".lightbox-close-button");
      closeButton?.addEventListener("click", () => {
        lightbox.classList.add("hide");
        const scrollY = document.body.style.top;
        document.body.style.position = "";
        document.body.style.top = "";
        window.scrollTo(0, parseInt(scrollY || "0") * -1);
      });
    });
  }
});
